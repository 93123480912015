.fade {
  opacity: 0;
  animation: 0.15s ease-in 0s 1 normal forwards running fadeIn;
}

.fade-0 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.fade-1 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.fade-2 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.fade-3 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.fade-4 {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.fade-5 {
  -webkit-animation-duration: 0.6s;
  animation-duration: 0.6s;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.fade-6 {
  -webkit-animation-duration: 0.6s;
  animation-duration: 0.6s;
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
