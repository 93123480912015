:root,
.light {
  --gradient: linear-gradient(
    120deg,
    #ff7978,
    #ff7978,
    #ff8a58,
    #ff8a58,
    #ff8a58
  );
  --blue: #3182eb;
  --blue-dark: #0a499a;
  --text-color: #282828;
  --pink: #ff7978;
  --muted: #fafafa;
  --blue-gradient: linear-gradient(147.98deg, #28aefa 8.76%, #3182eb 73.97%);
  --green-gradient: linear-gradient(132.26deg, #38ee81 8.76%, #54b74b 73.97%);

  --bs-header: 0px 30px 30px rgba(0, 0, 0, 0.02),
    0px 0px 8px rgba(0, 0, 0, 0.03), 0px 1px 0px rgba(0, 0, 0, 0.05);
  --bs-drop: 0px 15px 30px rgba(0, 0, 0, 0.1);
}

.dark {
  --bs-header: 0px 1px 0px rgba(255, 255, 255, 0.05),
    0px 0px 8px rgba(255, 255, 255, 0.03),
    0px 30px 30px rgba(255, 255, 255, 0.02);
}

html.dark {
  background-color: #272f37;
}

body {
  overflow-x: hidden;
}

.hover\:trigger .hover\:target {
  opacity: 0;
}

.hover\:trigger:hover .hover\:target {
  opacity: 1;
}

.react-syntax-highlighter-line-number {
  opacity: 0.5;
}

pre.count-lines {
  counter-reset: line var(--line-num, 0);
}
pre.count-lines div {
  counter-increment: line;
}
pre.count-lines div::before {
  content: counter(line) ' ';
  text-align: right;
  width: 2.4rem;
  opacity: 0.5;
  display: inline-block;
  margin-right: 0.4rem;
}

.tw-select {
  background-image: url(/static/images/chevron.svg);
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  appearance: none;
}

select:not(:valid) {
  color: red;
}

/* https://docs.osano.com/hiding-the-cookie-widget */
/* Search `cookie` in components/Footer/index.js */
.osano-cm-widget {
  display: none;
}

/* Osano css customizations */
/* https://docs.osano.com/css-for-customizing-/-styling-osano-cmp */
.osano-cm-window {
  font-family: 'Inter';
  font-size: 12px;
  line-height: 1.4;
  font-weight: 500;
}

.osano-cm-dialog {
  line-height: 1.4;
  border-radius: 12px;
  border: 0.5px solid #eaedf0;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05), 0px 0px 8px rgba(0, 0, 0, 0.03),
    0px 30px 30px rgba(0, 0, 0, 0.02);
  background: white;
  color: #68717a;
}

.osano-cm-buttons {
  gap: 5px;
}

.osano-cm-content__message {
  margin-bottom: 0;
}

.osano-cm-button {
  color: #68717a;
}
.osano-cm-button:hover,
.osano-cm-button:active,
.osano-cm-button:focus {
  background: initial;
  color: initial;
  opacity: 0.9;
}
.osano-cm-button {
  border: 0.5px solid #eaedf0;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05), 0px 0px 8px rgba(0, 0, 0, 0.03),
    0px 30px 30px rgba(0, 0, 0, 0.02);
  border-radius: 100px;
  color: black;
  background: white;
  padding: 10px 14px;
}
.osano-cm-button {
  text-transform: lowercase;
}
.osano-cm-button:first-letter {
  text-transform: uppercase;
}
.osano-cm-button--type_manage,
.osano-cm-storage-policy {
  border: 0;
  display: block;
  width: 100%;
  padding: 0;
  font-weight: 500 !important;
  text-align: left;
  background: transparent;
  color: #68717a !important;
  text-decoration: underline !important;
  text-decoration-color: #eaedf0 !important;
  font-size: 12px;
  box-shadow: none;
  border: 0;
  font-size: 11px;
}

.osano-cm-accept-all,
.osano-cm-accept-all:hover,
.osano-cm-accept-all:focus {
  background-image: linear-gradient(170.84deg, #fc8d39 8.76%, #ff6c81 73.97%);
  color: white;
}

.osano-cm-button--type_manage {
  margin-bottom: 16px;
}

.osano-cm-dialog__close {
  color: black !important;
  stroke: black !important;
  opacity: 0.3;
  transition: none;
  stroke-width: 2px;
}
.osano-cm-dialog__close svg,
.osano-cm-dialog__close {
  width: 12px;
  min-width: 12px;
  min-height: 12px;
  height: 12px;
}

.osano-cm-dialog__close:hover {
  opacity: 0.2;
}

.osano-cm-dialog__close:focus {
  outline: none;
}

.ReactCollapse--collapse {
  transition: height 300ms;
}


/* Gradients for cards/sections */

.radial-gradient__dark_grey {
  background: radial-gradient(226.64% 1035.02% at 1.92% -0.04%, #22262A 0%, rgba(34, 38, 42, 0.42) 100%);
}

.radial-gradient__dark_grey p {
  color: #ADB5BD;
}

.radial-gradient__blue_purple {
  background: radial-gradient(181.10% 161.28% at 118.08% 164.21%, #66EDFF 15.10%, #01CBF7 48.18%, #7149E9 100%), radial-gradient(758.54% 128.61% at 0.00% 1.52%, #0DB66F 0%, #73B7F6 100%);
  border: 1px solid #DFE3E7;
}

.radial-gradient__blue_purple p {
  color: #D1ECFF;
}

.radial-gradient__leaf_green {
  background: radial-gradient(288.01% 701.74% at 0% 1.52%, #0DB66F 0%, #73B7F6 100%);
  border: 1px solid #DFE3E7;
}

.radial-gradient__leaf_green p {
  color: #D4FAD9;
}

.radial-gradient__red_orange {
  background: radial-gradient(288.01% 701.74% at 0% 1.52%, #FF5A13 0%, #FF932F 30%), #F9FAFB;
  border: 1px solid #DFE3E7;
}

.radial-gradient__red_orange p {
  color: #FFF5E7;
}

.radial-gradient__green_blue {
  background: radial-gradient(112.16% 101.39% at 100% 49.97%, #1D7FD7 24.66%, #41C158 88.55%);
  border: 1px solid #DFE3E7;
}

.radial-gradient__green_blue p {
  color: #E8F6FF;
}

.radial-gradient__pink_purple {
  background: radial-gradient(221.92% 141.42% at 0.00% -0.00%, #F9538F 12.76%, #AF04FF 64.15%, #6842DA 100%);
  border: 1px solid #DFE3E7;
}

.radial-gradient__pink_purple p {
  color: #FFDCF1;
}

.radial-gradient__bright_green {
  background: radial-gradient(201.46% 121.20% at 112.50% 110.98%, #53F9B3 0%, #00F981 36.59%, #309F45 100%), radial-gradient(278.10% 151.02% at 126.83% 108.84%, #FFE167 0%, #FBB106 37.31%, #E2520F 100%);
  border: 1px solid #DFE3E7;
}

.radial-gradient__bright_green p {
  color: #D4FAD9;
}

.radial-gradient__grey_black {
  background: radial-gradient(1129.09% 239.16% at 44.52% 72.11%, #22262A 0%, rgba(34, 38, 42, 0.62) 100%), radial-gradient(278.10% 151.02% at 126.83% 108.84%, #FFE167 0%, #FBB106 37.31%, #E2520F 100%);
  border: 1px solid #DFE3E7;
}

.radial-gradient__grey_black p {
  color: #CFD5DB;
}

.radial-gradient__pink_orange {
  background: radial-gradient(223.79% 123.99% at 89.42% 88.07%, #FF9262 15.64%, #FF2F6D 66.23%, #FF0D55 100%);
  border: 1px solid #DFE3E7;
}

.radial-gradient__pink_orange p {
  color: #FFE4E4;
}

/** one off override for dbt tutorial code container **/
.dbt-tutorial [class*="codeblock_code__container"] {
  max-width: 90vw;
}