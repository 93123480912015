.confetti {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 200px;
  margin-bottom: -200px;
  overflow: hidden;
}

.confetti-piece {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background: #ffb800;
  top: 0;
  opacity: 0;
}

.confetti-piece:nth-child(1) {
  left: 7%;
  transform: rotate(-63deg);
  animation: makeItRain 1000ms infinite ease-out;
  animation-delay: 91ms;
  animation-duration: 1112ms;
}

.confetti-piece:nth-child(2) {
  left: 14%;
  transform: rotate(47deg);
  animation: makeItRain 1000ms infinite ease-out;
  animation-delay: 362ms;
  animation-duration: 914ms;
}

.confetti-piece:nth-child(3) {
  left: 21%;
  transform: rotate(16deg);
  animation: makeItRain 1000ms infinite ease-out;
  animation-delay: 130ms;
  animation-duration: 1122ms;
}

.confetti-piece:nth-child(4) {
  left: 28%;
  transform: rotate(30deg);
  animation: makeItRain 1000ms infinite ease-out;
  animation-delay: 443ms;
  animation-duration: 846ms;
}

.confetti-piece:nth-child(5) {
  left: 35%;
  transform: rotate(70deg);
  animation: makeItRain 1000ms infinite ease-out;
  animation-delay: 43ms;
  animation-duration: 1080ms;
}

.confetti-piece:nth-child(6) {
  left: 42%;
  transform: rotate(-10deg);
  animation: makeItRain 1000ms infinite ease-out;
  animation-delay: 464ms;
  animation-duration: 969ms;
}

.confetti-piece:nth-child(7) {
  left: 49%;
  transform: rotate(24deg);
  animation: makeItRain 1000ms infinite ease-out;
  animation-delay: 19ms;
  animation-duration: 1039ms;
}

.confetti-piece:nth-child(8) {
  left: 56%;
  transform: rotate(-26deg);
  animation: makeItRain 1000ms infinite ease-out;
  animation-delay: 262ms;
  animation-duration: 733ms;
}

.confetti-piece:nth-child(9) {
  left: 63%;
  transform: rotate(78deg);
  animation: makeItRain 1000ms infinite ease-out;
  animation-delay: 482ms;
  animation-duration: 1139ms;
}

.confetti-piece:nth-child(10) {
  left: 70%;
  transform: rotate(34deg);
  animation: makeItRain 1000ms infinite ease-out;
  animation-delay: 302ms;
  animation-duration: 854ms;
}

.confetti-piece:nth-child(11) {
  left: 77%;
  transform: rotate(43deg);
  animation: makeItRain 1000ms infinite ease-out;
  animation-delay: 143ms;
  animation-duration: 984ms;
}

.confetti-piece:nth-child(12) {
  left: 84%;
  transform: rotate(45deg);
  animation: makeItRain 1000ms infinite ease-out;
  animation-delay: 186ms;
  animation-duration: 1007ms;
}

.confetti-piece:nth-child(13) {
  left: 91%;
  transform: rotate(22deg);
  animation: makeItRain 1000ms infinite ease-out;
  animation-delay: 430ms;
  animation-duration: 706ms;
}

.confetti-piece:nth-child(odd) {
  background: #3182eb;
}

.confetti-piece:nth-child(even) {
  z-index: 1;
}

.confetti-piece:nth-child(4n) {
  width: 5px;
  height: 12px;
  animation-duration: 2000ms;
}

.confetti-piece:nth-child(3n) {
  width: 3px;
  height: 10px;
  animation-duration: 2500ms;
  animation-delay: 1000ms;
}

.confetti-piece:nth-child(4n-7) {
  background: #fe766c;
}

@keyframes makeItRain {
  from {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  to {
    transform: translateY(200px);
  }
}
