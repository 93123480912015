@import 'tailwindcss/base';
/* Start purging... */
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
/* Stop purging. */

*,
*:before,
*:after {
  text-rendering: geometricPrecision;

  -webkit-font-smoothing: antialiased;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-variant-ligatures: common-ligatures;
}

body {
  font-family: 'Inter', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

h1 {
  @apply text-4xl;
}
h2 {
  @apply text-xl;
}
h3 {
  @apply text-lg;
}
p {
  @apply text-gray-500;
}
a {
  @apply text-blue-100;
}
hr {
  border: 0;
  height: 0;
  border-top: 1px solid #e2e2e2;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
