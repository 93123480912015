@charset 'UTF-8';
.slick-dots,
.slick-next,
.slick-prev {
  position: absolute;
  display: block;
  padding: 0;
}
.slick-dots li button:before,
.slick-next:before,
.slick-prev:before {
  font-family: slick;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-loading .slick-list {
  background: center center no-repeat #fff;
}
.slick-next,
.slick-prev {
  font-size: 0;
  line-height: 0;
  top: 50%;
  width: 20px;
  height: 20px;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0;
}
.slick-next:focus,
.slick-next:hover,
.slick-prev:focus,
.slick-prev:hover {
  color: transparent;
  outline: 0;
  background: 0 0;
}
.slick-next:focus:before,
.slick-next:hover:before,
.slick-prev:focus:before,
.slick-prev:hover:before {
  opacity: 1;
}
.slick-next.slick-disabled:before,
.slick-prev.slick-disabled:before {
  opacity: 0.25;
}
.slick-next:before,
.slick-prev:before {
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: #fff;
}
.slick-prev {
  left: -25px;
}
[dir='rtl'] .slick-prev {
  right: -25px;
  left: auto;
}
.slick-prev:before {
  content: '←';
}
.slick-next:before,
[dir='rtl'] .slick-prev:before {
  content: '→';
}
.slick-next {
  right: -25px;
}
[dir='rtl'] .slick-next {
  right: auto;
  left: -25px;
}
[dir='rtl'] .slick-next:before {
  content: '←';
}
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}
.slick-dots {
  bottom: -35px;
  width: 100%;
  margin: 0;
  list-style: none;
  text-align: center;
  z-index: 100;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.rectangle-dots .slick-dots.bg-grey {
  background: #2F3841;
  padding: 3rem 0 6rem;
  position: unset;
}
.rectangle-dots .slick-dots li {
  margin: 0 0.5rem;
  width: auto;
}
.dark .rectangle-dots .slick-dots li button {
  background: #4A5158;
}
.rectangle-dots .slick-dots li button {
  background: #EAEDF0;
  height: 0.875rem;
  width: 2.875rem;
  border-radius: 100px;
  transition: width 200ms;
}
.rectangle-dots .slick-dots li.slick-active button {
  background: linear-gradient(146.04deg, #00B2FF -81.22%, #EA8044 -10.26%, #EA5A63 30.06%, #EA4476 71.18%);
  width: 7.5rem;
}
.rectangle-dots .slick-dots li button:before {
  content: '';
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: 0;
  background: 0 0;
}
.slick-dots li button:focus,
.slick-dots li button:hover {
  outline: 0;
}
.slick-dots li button:focus:before,
.slick-dots li button:hover:before {
  opacity: 0.5;
}
.slick-dots li button:before {
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  font-size: 24px;
  content: '•';
  text-align: center;
  opacity: 0.1;
  color: white;
}
.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: white;
} /*# sourceMappingURL=slick-theme.min.css.map */

/* add the ability to select text on slides */
.slick-slider {
  user-select: text;
}

.dark .slick-slider ~ .slider-shadow {
  background: linear-gradient(179.79deg, rgba(39, 47, 55, 0) 40.34%, rgba(39, 47, 55, 0.94) 64%, #272F37 79.66%);
}

.slick-slider ~ .slider-shadow {
  background: linear-gradient(179.79deg, rgba(39, 47, 55, 0) 40.34%, rgb(255 255 255 / 94%) 64%, #ffffff 79.66%);
}